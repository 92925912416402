import React from 'react';

import { getImgUrl } from "../../../utils";

const About = () => (
  <div id="about" className="content-block about-block">
    <div className="about-block-inner container">
      <div className="animate-block content-block-inner text-center">
        <h2>About Us</h2>
        <div className="col-desktop-10 no-float center-element">
          <p>
            Solmation aims to take care of the technology side of your business. Our broad experience allows us to
            provide the best solutions for our clients so they can focus on their business. Technology should only be a
            means to a solution - and not a time sink.
          </p>
        </div>
      </div>
      <div className="animate-block about-content clear">
        <div className="what-we-do-block col-tablet-6">
          <h3 className="h3">What We Do</h3>
          <p>
            When someone asks what we do at Solmation, it's tempting to point to list out examples like designing an
            Asthma warning device to reverse engineering the US Air Force's very old radar system. Simply put, we use
            technology to solve your problems. If it is out of our expertise, we'll be the first to tell you.
          </p>
          <p>
            We are picky about who we work with. We have worked with top 500 fortune companies to mom & pop businesses.
            We work with top executives to help them make better decisions, convert those decisions to actions, and
            deliver the sustainable success they desire. For twenty years, we've been passionate about achieving better
            results for our clients-results that go beyond financial and are uniquely tailored, pragmatic, holistic, and
            enduring.
          </p>
        </div>
        <div className="our-clients-block col-tablet-6 clear">
        </div>
      </div>
      <div className="animate-block our-team-block">
        <h3 className="h3">Past Projects</h3>
        <ul id="team" className="team-list clear">
          <li className="team-item">
            <a id="team-1" href="#team-1-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/landing.jpg") }
                alt="Team Member 1"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Landing System</strong>
              <strong className="team-item-title">Replace Technology</strong>
              <span className="team-item-position">TPN-19</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-2" href="#team-2-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/radar.jpg") }
                alt="Team Member 2"
              />
            <span className="team-item-content">
              <strong className="team-item-name">RGB Recording</strong>
              <strong className="team-item-title">Losless MPEG</strong>
              <span className="team-item-position">ATC</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-12" href="#team-12-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/comm.jpg") }
                alt="Team Member 10"
              />
            <span className="team-item-content">
              <strong className="team-item-name">VOIP/SMS System</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">Communication Automation</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-3" href="#team-3-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/dusts.jpg") }
                alt="Team Member 3"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Dust Sensor</strong>
              <strong className="team-item-title">Asthma Prevention</strong>
              <span className="team-item-position"/>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-4" href="#team-4-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/webapp.jpg") }
                alt="Team Member 4"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Web Apps</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">Health Care</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-5" href="#team-5-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/moto360cropped.JPG") }
                alt="Team Member 5"
              />
              <span className="team-item-content">
              <strong className="team-item-name">Mobile Apps</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">B2B, Loyalty & Rewards</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-6" href="#team-6-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/deviced.jpg") }
                alt="Team Member 6"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Device Drivers</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">Network Switch, Temp controllers</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-7" href="#team-7-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/hospital.jpg") }
                alt="Team Member 7"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Patient Contact</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">EMR Integration, HIPPA Compliant</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-8" href="#team-8-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/homeforsale.jpg") }
                alt="Team Member 8"
              />
            <span className="team-item-content">
              <strong className="team-item-name">Valuation Models</strong>
              <strong className="team-item-title">Algorithms</strong>
              <span className="team-item-position">Fannie Mae, BOA</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-9" href="#team-9-profile">
              <img
                className="block img-full lazyload"
                data-src={ getImgUrl("img/eyediag.jpg") }
                alt="Team Member 9"
              />
            <span className="team-item-content">
              <strong className="team-item-name">FCC Compliance</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">EMC</span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-10" href="#team-10-profile"><img className="block img-full lazyload" data-src={ getImgUrl("img/thermal.jpg") }
                                                         alt="Team Member 10" />
            <span className="team-item-content">
              <strong className="team-item-name">Thermal Mitigation</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">Mechanical & Software </span>
            </span>
            </a>
          </li>
          <li className="team-item">
            <a id="team-11" href="#team-11-profile"><img className="block img-full lazyload" data-src={ getImgUrl("img/ds.jpg") }
                                                         alt="Team Member 11" />
            <span className="team-item-content">
              <strong className="team-item-name">Digital Signage</strong>
              <strong className="team-item-title"/>
              <span className="team-item-position">Wireless, Raspberry Pi</span>
            </span>
            </a>
          </li>
        </ul>
      </div>
      <div className="team-profiles-block">
        <div className="modal" id="team-1-profile">
          <a href="#team-1" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Landing System</h2>
            <strong className="block">TPN-19</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyloadvvvvv" data-src={ getImgUrl("img/landing.jpg") } alt="Landing System" />
                <div className="team-profile-content-block">
                  <p>
                    The AN/TPN 19 Landing Control Central is a complete
                    Radar Approach Control System. The project was to
                    replace the large electronics boards that decode the
                    digital signals from the radar with a single custom
                    designed PCB board.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-2-profile">
          <a href="#team-2" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">RGB Recording</h2>
            <strong className="block">Losless MPEG</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/radar.jpg") } alt="RGB Recording" />
                <div className="team-profile-content-block">
                  <p>
                    MPEG 2 is a lossy but a popular format. Designed a board that could record RGB data without losing
                    any quality. A proprietary algorithm was implemented in an FPGA. The application was to record an
                    ATC screen where single dots could be important.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-12-profile">
          <a href="#team-12" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">VOIP/SMS System</h2>
            <strong className="block">Communication system</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/comm.jpg") } alt="Team Member 12" />
                <div className="team-profile-content-block">
                  <p>
                    Application to make thousands of phone calls and SMS messages. Customers good response back using
                    text messages or phone calls for automating tasks. This is resulted in saving hundreds of man hours
                    per month.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-3-profile">
          <a href="#team-3" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Dust Sensor</h2>
            <strong className="block">Prototype Design</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyloadvvvvvvvvvv" data-src={ getImgUrl("img/dusts.jpg") } alt="Team Member 4" />
                <div className="team-profile-content-block">
                  <p>
                    When your kids have Asthma, dust may be a powerful trigger for it. The dust sensor prototype was
                    designed to run on batteries and update the current dust conditions such that a mobile phone could
                    read it - The dust sensor was sensitive upto 0.5V/0.1mg/m<sup>3</sup>.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-4-profile">
          <a href="#team-4" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Web Applications</h2>
            <strong className="block">Health Care</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/webapp.jpg") } alt="Team Member 4" />
                <div className="team-profile-content-block">
                  <p>
                    Develop web applications for the Health care focused for providing continuing care. Other
                    applications dealt with closed caption testing for video encoders. We can list a bunch more, but
                    seriously - we can do web apps.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-5-profile">
          <a href="#team-5" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Mobile Apps</h2>
            <strong className="block">B2B, Loyalty Rewards</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/moto360cropped.JPG") } alt="Team Member 5" />
                <div className="team-profile-content-block">
                  <p>
                    We've worked on mobile apps for media companies, contact sharing apps through bluetooth low energy
                    and games. We've done mostly android applications, but if you have iOS needs, let us know.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-6-profile">
          <a href="#team-6" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Device Drivers</h2>
            <strong className="block" />
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/deviced.jpg") } alt="Team Member 6" />
                <div className="team-profile-content-block">
                  <p>
                    We do some low level OS work with device drivers on the Linux OS. New microchips have 10+
                    temperature sensors in them, and heat is the critical environmental parameter for a healthy life for
                    an electronic product. We've also done device drivers for soft components inside FPGAs.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-7-profile">
          <a href="#team-7" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Patient Contact</h2>
            <strong className="block">EMR Integration</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/hospital.jpg") } alt="Team Member 7" />
                <div className="team-profile-content-block">
                  <p>
                    Clinics need to keep their seats filled - if not, they lose money. We designed Saas products to
                    notify patients through SMS, emails and phone calls.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-8-profile">
          <a href="#team-8" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Home Valuation</h2>
            <strong className="block">Automated Valuation Models</strong>
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/homeforsale.jpg") } alt="Team Member 8" />
                <div className="team-profile-content-block">
                  <p>
                    Years ago, you needed to send an appraiser to estimate the value of a home. Devolped one of the
                    first applications to determine home values using tax records and MLS information. Banks used this
                    product.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-9-profile">
          <a href="#team-9" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">FCC Compliance</h2>
            <strong className="block" />
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/eyediag.jpg") } alt="Team Member 9" />
                <div className="team-profile-content-block">
                  <p>
                    Once you design a hardware product, you are hardly done. Getting a product approved through
                    regulatory compliance is a difficult & costly endevour. We've been in the trenches, and are well
                    qualified to get you through this endeavour.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-10-profile">
          <a href="#team-10" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Thermal Mitigation</h2>
            <strong className="block" />
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/thermal.jpg") } alt="Team Member 10" />
                <div className="team-profile-content-block">
                  <p>
                    Heat kills an electronic product. Noise, fans, field serviceable, monitoring - these are all the
                    factors that need to be considered if one is the get the product done. We have been there, and can
                    help you.
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div className="modal" id="team-11-profile">
          <a href="#team-11" className="modal-close"><span className="sr">Close</span></a>
          <div className="modal-inner">
            <h2 className="team-profile-heading">Digital Signage</h2>
            <strong className="block" />
            <div className="clear team-profile-content">
              <img className="team-profile-image block lazyload" data-src={ getImgUrl("img/ds.jpg") } alt="Team Member 11" />
                <div className="team-profile-content-block">
                  <p>
                    There are plenty of digital signage options, but there are few options that work wirelesly, using
                    commodity devices like the Raspberry pi and that do not lock you into a contract.
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;
