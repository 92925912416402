import React from 'react';

const Service = () => (
  <div id="services" className="animate-block content-block services-block">
    <div className="services-block-inner container">
      <div className="content-block-inner text-center">
        <h2>Our Services</h2>
        <div className="col-desktop-10 no-float center-element">
          <p>
            We provide technology consulting services so you can focus on your business.
          </p>
        </div>
      </div>
      <ul className="services-list clear">
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-performance">Hardware Design</h3>
          <p>
            Design electronic products without compromising quality and minimizing cost.
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-customer">Software Design</h3>
          <p>
            Low level OS work to web application design.
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-it">Saas Applications</h3>
          <p>
            Design robust Saas applications that require little maintanence.
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-transformation">Compliance Solutions</h3>
          <p>
            Experts in governmental compliance from Federal Communications Commission, CA, HIPPA etc.
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-strategy">IT consulting</h3>
          <p>
            A well functioning IT solution should never let your hear this: "Our systems are down".
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-equity">Telco Applications</h3>
          <p>
            Provide automated communications systems using SMS, phones and emails.
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-digital">Mobile Applications</h3>
          <p>
            Design B2B applications for mobile devices
          </p>
        </li>
        <li className="service-item">
          <h3 className="service-item-heading service-icon service-icon-delivery">Application Integration</h3>
          <p>
            Implement clean communiction channels from multiple vendors through their API
          </p>
        </li>
      </ul>
    </div>
  </div>
);

export default Service;
