import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout";
import SolmSeo from "../components/solmation/seo";

import '../styles/index.scss';

import About from "../components/sections/About/About"
import Contact from "../components/sections/Contact/Contact"
import ContactFeature from "../components/sections/ContactFeature/ContactFeature"
import VideoFeature from "../components/sections/VideoFeature/VideoFeature"
import Service from "../components/sections/Services/Services"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      wowInit: false,
    };

    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(this.initWOW, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  initWOW = () => {
    const { wowInit } = this.state;
    if (!window) {
      this.initWOW();
      return;
    }
    if (!window.WOW) {
      this.initWOW();
      return;
    }
    if (!wowInit) {
      this.setState(() => ({
        wowInit: true,
      }), () => {
        const html = document.querySelector('html');
        html.className = html.className.replace(/\bno-js\b/, "js");

        const wow = new window.WOW({
          boxClass:     'animate-block',
          animateClass: 'active',
          offset:       1,
          mobile:       true,
          live:         process.env.NODE_ENV === 'production'
        });

        wow.init();
        wow.sync();
      });
    } else {
      clearInterval(this.interval);
    }
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js" async={ true } defer={ true } />
        </Helmet>
        <SolmSeo title='Solmation' />
        <Service />
        <ContactFeature />
        <About />
        <VideoFeature />
        <Contact />
        {/*{ !isSSR && (
          <>
            <Suspense fallback={<div />}>
              <Service />
            </Suspense>
            { counter === 1 && (
              <Suspense fallback={<div />}>
                <ContactFeature />
              </Suspense>
            ) }
            { counter === 2 && (
              <Suspense fallback={<div />}>
                <About />
              </Suspense>
            ) }
            { counter === 3 && (
              <Suspense fallback={<div />}>
                <VideoFeature />
              </Suspense>
            ) }
            { counter === 4 && (
              <Suspense fallback={<div />}>
                <Contact />
              </Suspense>
            ) }
          </>
        ) }*/}
      </Layout>
    )
  }
}

export default App
