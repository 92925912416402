import React from 'react';
import { getImgUrl } from "../../../utils";

const ContactFeature = () => (
  <div className="animate-block feature-block contact-feature-block">
    <img data-src={ getImgUrl('/img/requirements-banner-test.jpg') } src-set={ getImgUrl('/img/requirements-banner-small.jpg') } alt='requirements' className='bg-image lazyload' />
    <div className="feature-block-inner container">
      <div className="text-center">
        <h2 className="feature-block-heading">Contact us for a free, no obligation confidential exploration of your
          needs</h2>
        <a className="button button-primary" href="#contact">Contact Us</a>
      </div>
    </div>
  </div>
);

export default ContactFeature;
