import React from 'react';

const VideoFeature = () => (
  <section id="video-feature-block" className="feature-block video-feature-block">
    <img data-src="img/video-banner.jpg" alt="video-banner" className='bg-image lazyload' />
    <div className="video-feature-inner container">
      <div className="animate-block content-block-inner text-center">
        <a id="video" className="button button-play" href="#feature-video"><span className="sr">Play</span></a>
        <h2 className="feature-block-heading">Technology implementation should be invisible</h2>
        <div className="col-desktop-10 no-float center-element">
        </div>
      </div>
      <div className="modal" id="feature-video">
        <a href="#video" className="modal-close"><span className="sr">Close</span></a>
        <div className="modal-inner">
          <p>
            We strive to design software or hardware products that do not lock you in a relationship with us - this is
            the main driver for us to be innovative.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default VideoFeature;
