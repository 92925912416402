import React from 'react';

const Contact = () => (
  <section id="contact" className="animate-block content-block contact-block">
    <img data-src='img/contact-banner.jpg' alt='contact-banner' className='bg-image lazyload' />
    <div className="contact-block-inner container">
      <div className="clear">
        <div className="contact-block-content col-tablet-6">
          <h2 className="h2 uppercase">Solmation</h2>
          <p>
            Thank you for your interest in SOLMATION. Please contact us using the information below.
          </p>
          <ul className="contact-list">
            <li><span className="icon contact-icon contact-icon-location">3475 Lenox Road NE, Suite 400, Atlanta, GA 30326</span>
            </li>
            <li><span className="icon contact-icon contact-icon-phone">+1 (678) 710-9419</span></li>
            <li><span className="icon contact-icon contact-icon-email"><a
              href="mailto:info@solmation.com?subject=General%20Enquiry&anp;body=">info@solmation.com</a></span></li>
          </ul>
        </div>
        <div className="contact-block-form col-tablet-6">
          <h2 className="h2">Contact</h2>
          <p>
            Please contact us using contact form below.
          </p>
          <form className="contact-form">
            <fieldset>
              <legend className="sr">Contact Us</legend>
              <div className="field-group">
                <label className="sr" htmlFor="name">Name</label>
                <input name="name" className="field" id="name" type="text" placeholder="Name" />
              </div>
              <div className="field-group">
                <label className="sr" htmlFor="email">Email</label>
                <input name="email" className="field" id="email" type="email" placeholder="Email" />
              </div>
              <div className="field-group">
                <label className="sr" htmlFor="subject">Date</label>
                <input name="subject" className="field" id="subject" type="text" placeholder="Subject" />
              </div>
              <div className="field-group">
                <label htmlFor="message" className="sr">Message</label>
                <textarea placeholder="Message" className="field" name="message" id="message" cols="30"
                          rows="7" />
              </div>
              <div className="text-right">
                <input type="submit" className="button button-primary contact-submit" value="Send" />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
